import { create } from 'zustand'
import { combine } from 'zustand/middleware'

type AlertOptions = {
	title: string
	description?: string
	/**
	 * not implemented
	 */
	actions?: {
		label: string
		onClick: () => void
	}[]
	onClose?: () => void
}

export const useAlertDialog = create(
	combine(
		{
			alert: null as AlertOptions | null,
		},
		(set) => ({
			show: (alert: AlertOptions) => set({ alert }),
			hide: () => set({ alert: null }),
		}),
	),
)

export function useShowAlert() {
	return useAlertDialog(({ show }) => show)
}

export function useHideAlert() {
	return useAlertDialog(({ hide }) => hide)
}
