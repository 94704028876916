import { forwardRef } from 'react'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { IconChevronDown } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import TonimIcon from '@/assets/icons/tonim-front-small.svg?react'

import './styles.css'

const ListItem = forwardRef(function ListItemWithRef(
	{ children, title, as, ...props }: any,
	forwardedRef,
) {
	const Component = as || 'a'

	return (
		<li>
			<NavigationMenu.Link asChild>
				<Component className="ListItemLink" {...props} ref={forwardedRef}>
					<div className="ListItemHeading">{title}</div>
					<p className="ListItemText">{children}</p>
				</Component>
			</NavigationMenu.Link>
		</li>
	)
})

export default function NavigationMenuDemo() {
	const { t } = useTranslation()

	return (
		<NavigationMenu.Root className="NavigationMenuRoot">
			<NavigationMenu.List className="NavigationMenuList">
				<NavigationMenu.Item>
					<Link
						style={{ display: 'inline-flex', flexDirection: 'row', gap: 8 }}
						className="NavigationMenuLink"
						to="/"
					>
						<TonimIcon width={20} height={20} />
						{t('modules.header.home.title')}
					</Link>
				</NavigationMenu.Item>

				{/* <NavigationMenu.Item>
					<Link className="NavigationMenuLink" to="store">
						Store
					</Link>
				</NavigationMenu.Item> */}

				<NavigationMenu.Item>
					<NavigationMenu.Link
						className="NavigationMenuLink"
						href="https://theyxolo.art/"
					>
						{t('modules.header.theyXolo.title')}
					</NavigationMenu.Link>
				</NavigationMenu.Item>

				{/* <NavigationMenu.Item>
					<NavigationMenu.Trigger className="NavigationMenuTrigger">
						Coin <CaretDownIcon className="CaretDown" aria-hidden />
					</NavigationMenu.Trigger>
					<NavigationMenu.Content className="NavigationMenuContent">
						<ul className="List one">
							<li style={{ gridRow: 'span 3' }}>
								<NavigationMenu.Link
									style={{ backgroundColor: 'gray' }}
									asChild
								>
									<a className="Callout" href="/">
										<TonimIcon />
										<div className="CalloutHeading">$TNM Coin</div>
										<p className="CalloutText">
											A Coin to empower Latin art projects.
										</p>
									</a>
								</NavigationMenu.Link>
							</li>
							<ListItem as={Link} href="/tokenomics" title="Tokenomics">
								Learn more about $TNM tokenomics.
							</ListItem>
							<ListItem as={Link} href="/purchase" title="Purchase">
								Beautiful, thought-out palettes with auto dark mode.
							</ListItem>
							<ListItem href="https://icons.radix-ui.com/" title="Vote">
								Vote and create proposals for the Tonim DAO.
							</ListItem>
						</ul>
					</NavigationMenu.Content>
				</NavigationMenu.Item> */}

				<NavigationMenu.Item>
					<NavigationMenu.Trigger className="NavigationMenuTrigger">
						{t('modules.header.utilities.title')}
						<IconChevronDown aria-hidden />
					</NavigationMenu.Trigger>
					<NavigationMenu.Content className="NavigationMenuContent">
						<ul className="List two">
							<ListItem
								title={t('modules.header.secretSanta.title')}
								as={Link}
								to="/secret-santa"
							>
								{t('modules.header.secretSanta.description')}
							</ListItem>
							<ListItem
								as="a"
								title={t('modules.header.mejor.title')}
								href="https://mejor.tonim.xyz/"
							>
								{t('modules.header.mejor.description')}
							</ListItem>
							{/* 
							<ListItem title="Anymint" as={Link} to="/anymint">
								Mint a new NFT in a community collection.
							</ListItem>
							<ListItem
								title="Mintail"
								href="/docs/primitives/overview/getting-started"
							>
								Live mint interface for any collection.
							</ListItem>
							<ListItem title="Flips" as={Link} to="flips">
								Bet and flip a coin.
							</ListItem>
							<ListItem as={Link} title="Pass Proof" to="/pass-proof">
								Scan and token gate IRL events with this utility.
							</ListItem> */}
						</ul>
					</NavigationMenu.Content>
				</NavigationMenu.Item>

				<NavigationMenu.Indicator className="NavigationMenuIndicator">
					<div className="Arrow" />
				</NavigationMenu.Indicator>
			</NavigationMenu.List>

			<div className="ViewportPosition">
				<NavigationMenu.Viewport className="NavigationMenuViewport" />
			</div>
		</NavigationMenu.Root>
	)
}
