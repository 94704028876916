import styled from 'styled-components'

const Content = styled.main`
	min-height: calc(100vh - 60px);
	min-height: calc(100svh - 60px);
	max-width: 1200px;
	margin: 60px auto 0;
	padding: var(--space--small);
	display: grid;
`

export default Content
