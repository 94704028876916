const INVALID_PROPS = [
	'gap',
	'maxWidth',
	'minWidth',
	'justifyContent',
	'flexDirection',
	'alignItems',
]

export function shouldForwardProp(prop: string) {
	return !INVALID_PROPS.includes(prop)
}
