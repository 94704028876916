import styled from 'styled-components'

const FooterContainer = styled.footer`
	max-width: 800px;
	display: grid;
	place-items: center;
	font-size: 0.75rem;
	margin: var(--space--large) auto var(--space--medium);
	color: var(--colors--text_secondary);
`

export default function Footer() {
	return (
		<FooterContainer>
			<p>
				v{__APP_VERSION__} &copy; Tonim {new Date().getFullYear()}
			</p>
		</FooterContainer>
	)
}
