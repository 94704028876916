import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'

import Error from '@/containers/Error'

const Coin = lazy(() => import('@/containers/Coin'))
const Store = lazy(() => import('@/containers/Store'))
const SecretSanta = lazy(() => import('@/containers/SecretSanta'))
const Home = lazy(() => import('@/containers/Home'))
const NotFound = lazy(() => import('@/containers/NotFound'))

import Root from './Root'

export default createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		children: [
			{ path: '', errorElement: <Error />, element: <Home /> },
			{
				path: 'secret-santa',
				errorElement: <Error />,
				element: <SecretSanta />,
			},
			{ path: 'coin', errorElement: <Error />, element: <Coin /> },
			{ path: 'store', errorElement: <Error />, element: <Store /> },
			{ path: '*', errorElement: <Error />, element: <NotFound /> },
		],
	},
])
