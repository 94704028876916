import * as RadixAlertDialog from '@radix-ui/react-alert-dialog'

import { useAlertDialog } from '@/lib/zustand'

import './styles.css'

export default function AlertDialog() {
	const { alert, hide } = useAlertDialog(({ alert, hide }) => ({ alert, hide }))

	if (!alert) return null

	return (
		<RadixAlertDialog.Root open>
			<RadixAlertDialog.Portal>
				<RadixAlertDialog.Overlay className="AlertDialogOverlay" />
				<RadixAlertDialog.Content className="AlertDialogContent">
					{alert.title && (
						<RadixAlertDialog.Title className="AlertDialogTitle">
							{alert.title}
						</RadixAlertDialog.Title>
					)}
					{alert.description && (
						<RadixAlertDialog.Description className="AlertDialogDescription">
							{alert.description}
						</RadixAlertDialog.Description>
					)}
					<div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
						{alert.actions ? (
							alert.actions.map((action, index) => (
								<RadixAlertDialog.Cancel key={index} asChild>
									<button onClick={action.onClick} className="Button mauve">
										{action.label}
									</button>
								</RadixAlertDialog.Cancel>
							))
						) : (
							<RadixAlertDialog.Cancel asChild>
								<button onClick={hide} className="Button mauve">
									OK
								</button>
							</RadixAlertDialog.Cancel>
						)}
					</div>
				</RadixAlertDialog.Content>
			</RadixAlertDialog.Portal>
		</RadixAlertDialog.Root>
	)
}
