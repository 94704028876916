import * as i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const { VITE_ENV } = import.meta.env

import en from './en/common.json'
import es from './es/common.json'

const resources = {
	en: { common: en },
	es: { common: es },
}

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		ns: 'common',
		resources,
		compatibilityJSON: 'v4',
		debug: VITE_ENV === 'development',
		keySeparator: false,
		fallbackLng: Object.keys(resources)[0],
		interpolation: { escapeValue: false },
	})
