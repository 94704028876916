/* eslint-disable simple-import-sort/imports */
import '@/lib/sentry'
// import '@/lib/analytics'
import '@/lib/localization'
/* eslint-enable simple-import-sort/imports */

import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Outlet } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'

import Layout from '@/modules/Layout'

import { shouldForwardProp } from '@/lib/styled'

import { GlobalStyle } from '@/GlobalStyles'

const queryClient = new QueryClient({
	defaultOptions: { queries: { staleTime: Infinity } },
})

export default function Root() {
	return (
		<HelmetProvider>
			<StyleSheetManager shouldForwardProp={shouldForwardProp}>
				<GlobalStyle />
				<QueryClientProvider client={queryClient}>
					<Layout>
						<Outlet />
					</Layout>
				</QueryClientProvider>
			</StyleSheetManager>
		</HelmetProvider>
	)
}
