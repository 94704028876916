import { Helmet } from 'react-helmet-async'

export default function SEO({
	title,
	description,
	image,
	url,
	type,
}: {
	title?: string
	description?: string
	image?: string
	url?: string
	type?: string
}) {
	return (
		<Helmet defaultTitle="Tonim" titleTemplate="%s - Tonim">
			{Boolean(title) && (
				<>
					<title>{title}</title>
					<meta name="twitter:title" content={title} />
					<meta property="og:title" content={title} />{' '}
				</>
			)}
			{Boolean(description) && (
				<>
					<meta name="description" content={description} />
					<meta property="og:description" content={description} />
					<meta name="twitter:description" content={description} />
				</>
			)}
			{Boolean(image) && (
				<>
					<meta name="image" content={image} />
					<meta property="og:image" content={image} />
					<meta name="twitter:image" content={image} />{' '}
				</>
			)}
			{Boolean(url) && <meta property="og:url" content={url} />}
			{Boolean(type) && <meta property="og:type" content={type} />}
		</Helmet>
	)
}
