import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
	:root {
		--colors--background_content: #fff;

		--space--xxsmall: 2px;
		--space--xsmall: 4px;
		--space--small: 8px;
		--space--medium: 16px;
		--space--large: 32px;
		--space--xlarge: 64px;

		--border_radius--small: 16px;
		--border_radius--medium: 22px;
		--border_radius--large: 32px;
		--border_radius--xlarge: 64px;
		--border_radius--full: 9999px;

		--page-max-width: 1200px;
		--nav-height: 72px;

		--button_size--small: 42px;
		--button_size: 57px;
		--button_size--large: 72px;

		--font_size--xsmall: 12px;
		--font_size--small: 14px;
		--font_size: 16px;
		--font_size--large: 18px;
		--font_size--xlarge: 20px;

	
		--colors--background: #ffffff;
		--colors--background_alternate: #f6f6f6;
		--colors--background_opposite: #000;
		--colors--background_opposite_transparent: rgba(0, 0, 0, 0.7);
		--colors--background_transparent: rgba(255, 255, 255, 0.7);
		--colors--background_transparent--full: rgba(255, 255, 255, 0);
		--colors--overlay: rgba(242, 242, 242, 0.3);
		--colors--overlay_opposite: rgba(0, 0, 0, 0.75);
		--colors--border: #d9d9d9;
		--colors--border_subtle: #e9e9e9;

		--colors--text: #111;
		--colors--text_secondary: #666;
		--colors--text_opposite: #fff;

		/* Dark mode */
		@media (prefers-color-scheme: dark) {
			--colors--text: #fff;
			--colors--text_secondary: #ccc;
			--colors--text_opposite: #000;

			--colors--background: #000000;
			--colors--background_alternate: #0F0F0F;
			--colors--background_opposite: #fff;
			--colors--background_opposite_transparent: rgba(255,255,255, 0.5);
			--colors--background_transparent: rgba(0, 0, 0, 0.7);
			--colors--background_transparent--full: rgba(0, 0, 0, 0);
			--colors--overlay: rgba(0, 0, 0, 0.3);
			--colors--overlay_opposite: rgba(242, 242, 242, 0.75);
			--colors--border: #292929;
			--colors--border_subtle: #191919;
		}
	}

	@media (min-width: 768px) {
		:root {
			--space--xsmall: 8px;
			--space--small: 10px;
			--space--medium: 18px;

			--button_size--small: 32px;
			--button_size: 42px;
			--button_size--large: 57px;
		}
	}

	@media (min-width: 1024px) {
		:root {
			--space--xsmall: 10px;
			--space--small: 14px;
			--space--medium: 22px;
		}
	}

	@font-face {
		font-family: 'Noto Serif';
		src: url('/fonts/Merriweather/Merriweather-BoldItalic.ttf');
	}

	@font-face {
		font-family: system-ui;
		font-style: italic;
		font-weight: 500;
		src: local('.SFNS-MediumItalic'), local('.SFNSText-MediumItalic'),
			local('.HelveticaNeueDeskInterface-MediumItalicP4'),
			local('.LucidaGrandeUI'), local('Segoe UI Semibold Italic'),
			local('Ubuntu Medium Italic'), local('Roboto-MediumItalic'),
			local('DroidSans-Bold'), local('Tahoma Bold');
	}

	* {
		box-sizing: border-box;
	}

	body {
		background-color: var(--colors--background);
	}

	html,
	body,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	button,
	input[type='submit'],
	textarea,
	a {
		line-height: normal;
		font-family: 'system-ui';
		color: var(--colors--text);
	}

	p {
		font-weight: 300;
	}

	a {
		text-decoration: underline;
	}

	a,
	button,
	input {
		&:focus {
			outline: none;
			box-shadow: 0 0 0 2px var(--colors--pitaya);
		}
	}

	button,
	input[type='submit'] {
		background-color: transparent;
		border: none;
		font-size: 0.9rem;
		font-weight: 500;
		cursor: pointer;
		margin: 0;
		transition: 0.3s;
	}
	input[type='submit'][disabled],
	button[disabled] {
		cursor: not-allowed;
		opacity: 0.7;
	}

	input[type='submit']:active,
	button:active,
	a:active {
		transform: scale(0.95);
	}

	input,
	textarea {
		color: black;
		display: block;
		width: 100%;
	}

	h1,
	.heading {
		font-size: 1.4rem;
		margin-bottom: 0.5rem;
		text-align: left;
		font-weight: 800;
		font-family: 'system-ui';
	}

	.heading {
		letter-spacing: 3px;
		font-weight: 300;
		text-transform: uppercase;
	}

	p {
		margin-bottom: 1rem;
		line-height: 1.2;
	}

	b {
		font-weight: bolder;
	}

	a,
	button,
	input[type='submit'] {
		min-height: var(--button_size--small);
		min-width: var(--button_size--small);
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}

	a {
		color: inherit;
	}
	a[aria-disabled='true'], a[disabled] {
		opacity: 0.5;
		pointer-events: none;
		cursor: not-allowed;
	}

	a:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}

	::selection {
		background: #ffb7b7; /* WebKit/Blink Browsers */
	}
	::-moz-selection {
		background: #ffb7b7; /* Gecko Browsers */
	}

	img {
		-webkit-user-drag: none;
	}

	strong,
	b {
		font-weight: bolder;
	}

	[role='dialog'] {
		z-index: 9999;
	}

	hr {
		width: 100%;
		border: none;
		border-top: 2px solid var(--colors--border);
		margin: var(--space--xsmall) 0;
	}

	[role='tablist'] button {
		background-color: transparent;
		backdrop-filter: none;
		border-radius: 0;
		border-bottom: 3px solid transparent;
		transition: none;
		padding: var(--space--small);
	}
	[role='tablist'] button span {
		display: inline-block;
		background-color: black;
		margin: 0 4px;
		border-radius: 4px;
		color: white;
		font-size: 0.6rem;
		padding: 2px 6px;
	}
	[role='tablist'] button[data-state='active'] {
		border-bottom: 3px solid var(--colors--border);
	}
	[role="checkbox"] {
		display: inline-block;
		border: 1px solid var(--colors--border);
		width: var(--button_size--small);
		height: var(--button_size--small);
		border-radius: 8px;
		&[data-state="checked"] {
			background-color: var(--colors--pitaya);
		}
	}

	html {
		font-size: 16px;
	}

	@media screen and (min-width: 320px) {
		html {
			font-size: calc(16px + 6 * ((100vw - 320px) / 680));
		}
	}

	@media screen and (min-width: 1000px) {
		html {
			font-size: 20px;
		}
	}
`
