import { lazy, Suspense } from 'react'
import { ErrorBoundary } from '@sentry/react'
import type { ReactNode } from 'react'

import Error from '@/containers/Error'

import AlertDialog from '@/modules/AlertDialog'
import Footer from '@/modules/Footer'
import Header from '@/modules/Header'

import Content from '@/components/Content'
const Providers = lazy(() => import('@/modules/Providers'))

export default function Layout({ children }: { children: ReactNode }) {
	return (
		<>
			<Header />
			<Content>
				<Suspense fallback={null}>
					<ErrorBoundary fallback={({ error }) => <Error error={error} />}>
						<Providers>{children}</Providers>
					</ErrorBoundary>
				</Suspense>
			</Content>
			<Footer />

			<AlertDialog />
		</>
	)
}
