import styled from 'styled-components'
import type {
	BackgroundProps,
	BottomProps,
	LeftProps,
	MaxHeightProps,
	MaxWidthProps,
	MinHeightProps,
	MinWidthProps,
	OverflowProps,
	RightProps,
	TopProps,
	WidthProps,
} from 'styled-system'
import {
	background,
	border,
	type BorderProps,
	bottom,
	flexbox,
	type FlexboxProps,
	grid,
	type GridProps,
	height,
	type HeightProps,
	left,
	margin,
	type MarginProps,
	maxHeight,
	maxWidth,
	minHeight,
	minWidth,
	overflow,
	position,
	type PositionProps,
	right,
	space,
	type SpaceProps,
	top,
	width,
} from 'styled-system'

type BoxProps = SpaceProps &
	MarginProps &
	HeightProps &
	PositionProps &
	BorderProps &
	WidthProps &
	BackgroundProps &
	MaxHeightProps &
	MaxWidthProps &
	MinWidthProps &
	MinHeightProps &
	OverflowProps &
	TopProps &
	LeftProps &
	BottomProps &
	RightProps

export const Box = styled.div<BoxProps>`
	${space}
	${margin}
  ${height}
  ${position}
	${border}
	${width}
	${background}
	${minWidth}
	${maxWidth}
	${minHeight}
	${maxHeight}
	${overflow}
	${top}
	${left}
	${right}
	${bottom}
`

export const Flex = styled(Box)<
	FlexboxProps & BoxProps & { gap?: number | string }
>`
	display: flex;
	${({ gap }) =>
		gap ? `gap: ${typeof gap === 'number' ? `${gap}px` : gap};` : undefined}
	${flexbox}
`

export const Grid = styled(Box)<
	GridProps & BoxProps & { gap?: number | string; alignItems?: string }
>`
	display: grid;
	${({ gap }) =>
		gap ? `gap: ${typeof gap === 'number' ? `${gap}px` : gap};` : undefined}
	${({ alignItems }) =>
		alignItems ? `align-items: ${alignItems};` : undefined}

  ${grid}
`
