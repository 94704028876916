import { useEffect } from 'react'
import { captureException, showReportDialog } from '@sentry/react'
import { Link } from 'react-router-dom'

import SEO from '@/modules/SEO'

import { Flex } from '@/components/system'

export default function Error({ error }: { error?: Error }) {
	useEffect(() => {
		if (error) {
			captureException(error)
		}
	}, [error])

	function handleShareFeedback() {
		showReportDialog()
	}

	return (
		<>
			<SEO title="Error" />
			<Flex flexDirection="column" gap="var(--space--medium)">
				<h1>Something broke</h1>
				<p>
					Please refresh the page or go <Link to="/">home</Link>
				</p>
				<button onClick={handleShareFeedback}>Share feedback</button>
			</Flex>
		</>
	)
}
